
  .App-logo {
    height: 36px;
    margin: 10px 0;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo-wrapper{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  