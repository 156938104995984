html, body{
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 !important;
    height: 100vh;
    background-color: #1018284d;
  }
#root{
    height: 100%;
}
.fullHeight{
    height: 100%;
}

.bodyContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.card{
    padding: 20px;
    gap: 1rem;
    color: rgb(152, 162, 179);
    font-size: 14px;
    background: rgb(252, 252, 253);
    box-shadow: rgb(16 24 40 / 30%) 0px 0.5px 2px;
    border-radius: 8px;
    max-width: 350px;
    margin: auto;

}  
.filterTodo > a{
    cursor: pointer;
    text-decoration: none;
    color: burlywood;
}
.filterTodo > a:visited{
    color: #61dafb;
}

.inline-form{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}
.rs-form-group{
    margin-bottom: 0px !important;
}
.rs-input, .rs-btn {
    border-radius: 0px !important;
    width: 100%;
}

.list-wrapper {
    margin-bottom: 24px;
}

.list-item {
    width: 100%;
    padding-left: 1em;
}

.list-item-wrapper{ 
    display: flex;
    justify-content: center;
    align-content: center;
}

.icon-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 46px;
}

.trash-icon {
    color: red
}